<template>
  <div class="exhibits" :class="[{ loading: isProjectLoading }]">
    <div
      v-if="isProjectReady"
      class="exhibition-gallery"
      v-on:pointerenter="pointerX = $event.pageX"
      v-on:pointermove="pointerX = $event.pageX"
    >
      <swiper
        v-bind:class="cursor"
        :slidesPerView="'auto'"
        :loop="true"
        :loopedSlides="1"
        :speed="speed"
        :preloadImages="false"
        :resizeObserver="true"
        :navigation="{
          nextEl: '.next',
          prevEl: '.prev',
          disabledClass: 'disabled',
        }"
        :keyboard="true"
        :lazy="{
          loadPrevNext: false,
          preloaderClass: 'lazy-preloader',
        }"
        @swiper="init"
        @slideChange="onSlideChange"
        @slideChangeTransitionEnd="onSlideSettle"
        @slideResetTransitionEnd="onSlideSettle"
        @sliderFirstMove="onTouchMove"
        @touchEnd="onTouchEnd"
        @[clickEvent]="prevNextSlide"
      >
        <swiper-slide v-for="(item, index) in media" :key="index">
          <div
            :data-background="item.dir + item.file"
            :class="item.scale"
            class="swiper-lazy backdrop"
          >
            <div class="details" v-if="item.title && item.caption">
              <p>
                <span v-html="item.title" />
                <span v-html="item.caption" />
              </p>
            </div>
            <div class="lazy-preloader"></div>
          </div>
        </swiper-slide>
        <button class="prev-next-button prev">
          <svg viewBox="0 0 100 100">
            <path
              d="M 16.85,50 L 63.26,96.41 L 67.19,92.47999999999999 L 24.71,50  L 67.19,7.520000000000003 L 63.26,3.5900000000000034 Z"
            />
          </svg>
        </button>
        <button class="prev-next-button next">
          <svg viewBox="0 0 100 100">
            <path
              d="M 16.85,50 L 63.26,96.41 L 67.19,92.47999999999999 L 24.71,50  L 67.19,7.520000000000003 L 63.26,3.5900000000000034 Z"
              transform="translate(100, 100) rotate(180)"
            />
          </svg>
        </button>
      </swiper>
      <SoundButton v-if="hasAudio" :source="project.audio" />
      <BackButton class="close-button button" title="Close gallery" />
      <FullscreenButton v-if="fullscreenSupport && fullscreen !== 'global'" />
      <div class="slide-progress">
        {{ slideProgress }}
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { LOAD_PROJECT, CLOSE_FULLSCREEN } from "@/store/handlers";
import { mapState, mapGetters } from "vuex";
import BackButton from "@/components/BackButton";
import SoundButton from "@/components/SoundButton";
import FullscreenButton from "@/components/FullscreenButton";
import SwiperCore, { Navigation, Keyboard, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";
SwiperCore.use([Navigation, Keyboard, Lazy]);

export default {
  components: {
    Swiper,
    SwiperSlide,
    BackButton,
    SoundButton,
    FullscreenButton,
  },
  data: function () {
    return {
      slideshow: null,
      speed: 800,
      moving: false,
      activeSlide: 0,
      dragging: null,
      pointerX: 0,
    };
  },
  computed: {
    ...mapState([
      "fullscreen",
      "fullscreenSupport",
      "project",
      "media",
      "mouseSupport",
      "slide",
      "status",
      "touchSupport",
      "windowWidth",
    ]),
    ...mapGetters([
      "isDesktop",
      "isFullscreen",
      "isProjectLoading",
      "isProjectReady",
      "isProjectShown",
    ]),
    clickEvent() {
      return this.mouseSupport ? "click" : null;
    },
    cursor() {
      return this.dragging
        ? this.dragging
        : this.mouseSupport
        ? this.direction
        : "drag";
    },
    direction() {
      return this.pointerX <= this.midpoint ? "previous" : "next";
    },
    hasAudio() {
      return !!this.project.audio;
    },
    midpoint() {
      return this.isFullscreen || !this.isDesktop
        ? this.windowWidth / 2
        : (this.windowWidth - 100) / 2 + 100;
    },
    showCaption() {
      //return (this.status === "success" && this.slide === 0);
      return this.slide === 0 && this.isProjectShown;
    },
    slideProgress() {
      return this.media
        ? this.activeSlide + 1 + " of " + this.media.length
        : "";
    },
  },
  watch: {
    isFullscreen() {
      this.$nextTick(() => {
        this.slideshow.update();
      });
    },
  },
  methods: {
    init(swiper) {
      this.slideshow = swiper;
    },
    onTouchMove() {
      this.dragging = "hold";
      this.moving = true;
    },
    onTouchEnd() {
      if (this.moving) this.dragging = "drag";
    },
    onSlideSettle() {
      this.dragging = null;
      this.moving = false;
    },
    onSlideChange(swiper) {
      this.activeSlide = swiper.realIndex;
    },
    prevNextSlide() {
      if (this.direction === "previous") {
        this.slideshow.slidePrev(this.speed);
      } else if (this.direction === "next") {
        this.slideshow.slideNext(this.speed);
      }
    },
  },
  beforeMount() {
    store.dispatch(LOAD_PROJECT, this.project.refId);
  },
  beforeRouteLeave(to, from, next) {
    if (this.fullscreen === "local") {
      store.dispatch(CLOSE_FULLSCREEN).then(() => {
        let watcher = store.watch(
          function (state) {
            return state.fullscreen;
          },
          function () {
            watcher();
            next();
          }
        );
      });
    } else next();
  },
};
</script>

<style>
.exhibition-gallery,
.exhibition-gallery > .swiper-container {
  width: 100%;
  height: 100%;
}

.pointer .swiper-slide {
  cursor: url("~@/assets/img/icons/cursor_pointer.png") 6 0, pointer !important;
}

.drag .swiper-slide {
  cursor: url("~@/assets/img/icons/cursor_drag.png") 14 0, move !important;
}

.hold .swiper-slide {
  cursor: url("~@/assets/img/icons/cursor_hold.png") 14 0, move !important;
}

.previous .swiper-slide {
  cursor: url("~@/assets/img/icons/cursor_left.png") 6 0, auto !important;
}

.next .swiper-slide {
  cursor: url("~@/assets/img/icons/cursor_right.png") 6 0, auto !important;
}

.exhibition-gallery .slide-progress {
  color: #ffffff;
  mix-blend-mode: difference;
}

.exhibition-gallery .backdrop {
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.exhibition-gallery .details {
  padding: 5rem 3rem;
}

@media only screen and (min-width: 769px) and (min-aspect-ratio: 4/3) {
  .exhibition-gallery .backdrop.cover {
    background-size: cover;
  }

  .exhibition-gallery .details {
    color: #ffffff;
    mix-blend-mode: difference;
  }
}

@media only screen and (min-width: 769px) {
  .exhibition-gallery .swiper-container .prev-next-button {
    display: none;
  }

  .exhibition-gallery > .swiper-container {
    padding: 0;
  }

  .exhibition-gallery .details {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 1.2rem;
  }
}
</style>
